import registerForm from './registerForm.hbs';
import generateErrorHandler from '../error/errorResponseHandler';
import contentBlockService from '../contentBlock/contentBlockService/contentBlockService';
import userService from '../_services/userService/userService';

const REGISTER_FORM_ELEMENT= '.js-element--registerForm';
const REGISTER_USERNAME_ELEMENT = '.js-element--registerUsername'
const REGISTER_EMAIL_ELEMENT = '.js-element--registerEmail'
const REGISTER_FIRSTNAME_ELEMENT = '.js-element--registerFirstName'
const REGISTER_LASTNAME_ELEMENT = '.js-element--registerLastName'
const REGISTER_METADATA_ELEMENT = '.js-element--userMetadata'

const initialFormValues = {
    username: '',
    email: '',
    firstName: '',
    lastName: '',
};

class RegisterFormController {
    constructor (container) {
        this.state = {
            errors: {},
            formValues: Object.assign({}, initialFormValues),
            metaDataOptions: {},
            loading: true,
            content: '',
            confirmationMessage: false
        };

        this.container = container;
        this.template = registerForm;

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.renderForm = this.renderForm.bind(this);
        this.disableLoadingState = this.disableLoadingState.bind(this);
        this.validateForm = this.validateForm.bind(this);

        Promise.allSettled([
            Collaterate.User.getUserMetadataOptions()
        ])
            .then( () => { this.getUserMetaData(); })
            .then( () => { this.getContentBlock(); })
            .then( () => { this.enableUI(); })
            .then( () => { this.disableLoadingState(); });
        return;
    }

    getContentBlock () {
        contentBlockService.getBlock('register-form-message')
            .then( (content) => {
                this.state.content = content;
            })
            .then(this.renderForm);
    }

    getUserMetaData () {
        return Collaterate.User.getUserMetadataOptions()
            .then(userMetadataOptions => {
                this.state.metaDataOptions = userMetadataOptions.map(items => ({...items, value: ''}));
            })
            .catch(generateErrorHandler());
    }

    enableUI () {
        this.container.addEventListener('submit', this.handleSubmit, false);
        this.container.addEventListener('input', this.handleChange, false);
    }

    renderForm () {
        this.container.innerHTML = this.template(this.state);
    }

    handleSubmit (e) {
        if (e.target.matches(REGISTER_FORM_ELEMENT)) {
            e.preventDefault();
            this.handleRegister();
            return;
        }
    }

    handleChange (e) {
        if (e.target.matches(REGISTER_USERNAME_ELEMENT)) {
            this.state.formValues.username = e.target.value
            return;
        }
        if (e.target.matches(REGISTER_EMAIL_ELEMENT)) {
            this.state.formValues.email = e.target.value
            return;
        }
        if (e.target.matches(REGISTER_FIRSTNAME_ELEMENT)) {
            this.state.formValues.firstName = e.target.value
            return;
        }
        if (e.target.matches(REGISTER_LASTNAME_ELEMENT)) {
            this.state.formValues.lastName = e.target.value
            return;
        }
        if (e.target.matches(REGISTER_METADATA_ELEMENT)) {
            const targetId = Number(e.target.closest('.sfForm-row-item').id);
            const targetObj = this.state.metaDataOptions.find(item => item.id === targetId);

            if (e.target.matches('input[type=checkbox]')) {
                targetObj.value = e.target.checked;
                return;
            }
            targetObj.value = e.target.value;
            return;
        }
    }

    handleRegister () {
        const registrationOptions = {
            username: this.state.formValues.username,
            email: this.state.formValues.email,
            firstName: this.state.formValues.firstName,
            lastName: this.state.formValues.lastName,
            metadata: this.state.metaDataOptions.map(item => ({ label: item.label, value: item.value, isRequired: item.isRequired }))
        };

        this.setValid();
        this.renderForm();

        if (!this.validateForm(registrationOptions)) {
            this.renderForm();
            return;
        }

        userService.regsiter(registrationOptions)
            .then(() => {
                this.state.confirmationMessage = true;
            })
            .catch(generateErrorHandler({
                list: e => {
                    e.errors.forEach(error => this.state.errors[error.field] = error.message);
                },
            }))
            .finally(this.disableLoadingState);
    }

    setValid () {
        this.state.errors = {};
    }

    validateForm (values) {
        let valid = true;

        Object.keys(values).forEach(key => {
            if (key !== 'metadata' ) {
                if (!values[key].trim()) {
                    this.state.errors[key] = 'This is a required field';
                    valid = false;
                }
            }
            if (key === 'metadata' ) {
                const metaDataArray = values[key];

                metaDataArray.forEach((key, index) => {
                    if (key.value === '' && key.isRequired === true) {
                        this.state.errors[index] = 'This is a required field';
                        valid = false;
                    }
                });
            }
        });

        return valid;
    }

    disableLoadingState () {
        this.state.loading = false;
        this.renderForm();
    }
}
export default RegisterFormController;