{{#if confirmationMessage }}
    <div class="hdg hdg_5">Thank you!</div>
    <p class="bdcpy">An email has been sent to you with instructions for completing your account registration.</p>
{{else}}
    <div class="vr{{#if loading}} isLoading{{/if}}">
        <div class="vr">
            <div class="hdg hdg_2">Register</div>
            {{#if content}}<p class="bdcpy">{{removeMarkup content}}</p>{{/if}}
        </div>
        <form class="sfForm js-element--registerForm" action="">
            <div class="sfForm-row sfForm-row_2up">
                <div class="sfForm-row-item isRequired{{#if errors.firstName}} sfForm-row-item_hasErrors{{/if}}" id="firstName">
                    <label class="sfForm-lbl" for="firstname">First Name</label>
                    <input class="sfForm-input js-element--registerFirstName" value="{{formValues.firstName}}" name="" id="firstname" type="text">
                    <div class="sfForm-msg sfForm-msg_error">{{errors.firstName}}</div>
                </div>
                <div class="sfForm-row-item isRequired{{#if errors.lastName}} sfForm-row-item_hasErrors{{/if}}" id="lastName">
                    <label class="sfForm-lbl" for="lastname">Last Name</label>
                    <input class="sfForm-input js-element--registerLastName" value="{{formValues.lastName}}" name="" id="lastname" type="text">
                    <div class="sfForm-msg sfForm-msg_error">{{errors.lastName}}</div>
                </div>
            </div>
            <div class="sfForm-row">
                <div class="sfForm-row-item isRequired{{#if errors.email}} sfForm-row-item_hasErrors{{/if}}" id="emailAddress">
                    <label class="sfForm-lbl" for="emailaddress">Email</label>
                    <input class="sfForm-input js-element--registerEmail" value="{{formValues.email}}" name="" id="emailaddress" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}$" type="email">
                    <div class="sfForm-msg sfForm-msg_error">{{errors.email}}</div>
                </div>
            </div>
            <div class="sfForm-row">
                <div class="sfForm-row-item isRequired{{#if errors.username}} sfForm-row-item_hasErrors{{/if}}" id="userName">
                    <label class="sfForm-lbl" for="regusername">Username</label>
                    <input class="sfForm-input js-element--registerUsername" value="{{formValues.username}}" name="" id="regusername" type="text">
                    <div class="sfForm-msg sfForm-msg_error">{{errors.username}}</div>
                </div>
            </div>
            {{#if metaDataOptions}}
                {{#each metaDataOptions}}
                    <div class="sfForm-row">
                        <div class="sfForm-row-item{{#if isRequired}} isRequired{{#with (lookup ../errors @index)}} sfForm-row-item_hasErrors{{/with}}{{/if}}" id="{{id}}">
                            <label class="sfForm-lbl" for="{{label}}">{{label}}</label>
                            {{#if isSelect}}
                                <select class="sfForm-input js-element--userMetadata" data-metadata-label="{{label}}" name="{{label}}" id="{{label}}">
                                    <option value="">-- Select an Option --</option>
                                    {{#each options}}
                                        <option value="{{value}}" {{#ifEquals ../value value}}selected="selected"{{/ifEquals}}>{{label}}</option>
                                    {{/each}}
                                </select>
                            {{/if}}
                            {{#unless isSelect}}
                                {{#unless isTextarea}}
                                    <input type="{{#if isCheckbox}}checkbox{{/if}}{{#if isDate}}date{{/if}}{{#if isText}}text{{/if}}"{{#if isCheckbox}}{{#if value}}checked{{/if}}{{/if}} class="sfForm-input js-element--userMetadata" data-metadata-label="{{label}}" name="{{label}}" id="{{label}}"{{#if value}}value="{{value}}"{{/if}} >
                                {{/unless}}
                            {{/unless}}
                            {{#if isTextarea}}
                                <textarea class="sfForm-input js-element--userMetadata" data-metadata-label="{{label}}" name="{{label}}" id="{{label}}" cols="30" rows="10">{{#if value}}{{value}}{{/if}}</textarea>
                            {{/if}}
                            <div class="sfForm-msg sfForm-msg_error">{{lookup ../errors @index}}</div>
                        </div>
                    </div>
                {{/each}}
            {{/if}}
            <div class="sfForm-row">
                <div class="sfForm-row-item">
                    <button class="btn btn_reversed">Register</button>
                </div>
            </div>
        </form>
    </div>
{{/if}}